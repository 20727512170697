import { useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import { Mail, Phone, Linkedin, Github } from 'lucide-react'
import SEO from '@/components/shared/SEOHead'
import { useLanguage } from "@/providers/LanguageProvider";
import { contactApi } from '@/api/contact';
import { toast } from 'sonner'

const Contact = () => {
  const { t } = useLanguage()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  })

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    
    await toast.promise(contactApi.sendMessage(formData), {
      loading: t('contact.sending'),
      success: () => {
        setIsSubmitting(false)
        setFormData({ name: '', email: '', message: '' })
        return t('contact.success')
      },
      error: () => {
        setIsSubmitting(false)
        return t('contact.error')
      },
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  } //

  return (
    <div className="container mx-auto py-12 px-4">
      <SEO 
        title={`Luis Febro - ${t('contact.title')}`}
        description="Get in touch with me for software development services, collaboration opportunities, or any questions about my work."
        keywords="contact developer, hire developer, software development services, web development contact, mobile development contact"
      />
      <h1 className="text-4xl font-bold mb-8">{t('contact.title')}</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <Card>
          <CardHeader>
            <CardTitle>{t('contact.title')}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="flex items-center gap-3">
              <Mail className="text-primary" />
              <span>contato@luisfebro.com</span>
            </div>
            <div className="flex items-center gap-3">
              <Phone className="text-primary" />
              <span>(92) 99382-8643</span>
            </div>
            <div className="flex gap-4 mt-6">
              <a
                href="https://linkedin.com/in/luisfebro"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-primary"
              >
                <Linkedin size={24} />
              </a>
              <a
                href="https://github.com/luisFebro"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-primary"
              >
                <Github size={24} />
              </a>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>{t('contact.sendButton')}</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={isSubmitting ? undefined : handleSubmit} className="space-y-4">
              <div>
                <Input
                  placeholder={t('contact.nameLabel')}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <Input
                  type="email"
                  placeholder={t('contact.emailLabel')}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <Textarea
                  placeholder={t('contact.messageLabel')}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="min-h-[150px]"
                />
              </div>
              <Button 
                type="submit" 
                className="w-full" 
                disabled={isSubmitting}
                aria-disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="flex items-center gap-2">
                    {t('contact.sending')}
                  </span>
                ) : (
                  t('contact.sendButton')
                )}
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default Contact 